import React, {useState} from "react";
import "../../static/css/forms_style/forms_style.css";
import "./ContactUs.scss";
import { Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import MuiPhoneNumber from "material-ui-phone-number";
import { FormattedMessage } from 'react-intl';

function ContactUs(props) {
  const [phoneNumber, setPhoneNumber] = useState();

  return (
    <div id="ContactUs">
      <h2 className="form_name"><FormattedMessage id="Contact"/></h2>
      <div className="content_wrapper form_wrapper">
        <h3 className="form_name" style={{ textAlign: "left" }}>
          <Box
            component="strong"
            className="title_line"
            sx={{ display: "inline-block" }}
          >
          
            <FormattedMessage id="Contact"/>
          </Box>
        </h3>
        <form action="" className="form_auth" method="post">
          <Box sx={{ mb: 2, mt: 3 }}>
            <div className="form_group">
              <label htmlFor="" className="form_label">
                  <FormattedMessage id="NAME"/>:<span className="star_symbol">*</span>
              </label>
              <input
                type="text"
                className="form_control"
                placeholder="Name..."
              />
            </div>
          </Box>

          <Box sx={{ mb: 2 }}>
            <div className="form_group">
              <label htmlFor="" className="form_label">
              <FormattedMessage id="email"/>: <span className="star_symbol">*</span>
              </label>
              <input
                type="email"
                className="form_control"
                placeholder="Email..."
              />
            </div>
          </Box>

          <Box sx={{ mb: 2, position: "relative" }}>
            <div className="form_group">
              <label htmlFor="" className="form_label">
              <FormattedMessage id="Phone"/>:
              </label>
              <div className="form_country_number_control">
                <PhoneInput
                  international
                  defaultCountry="BD"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
              </div>
            </div>
          </Box>

          <Box sx={{ mb: 2 }}>
            <div className="form_group">
              <label htmlFor="" className="form_label">
              <FormattedMessage id="Message"/>: <span className="star_symbol">*</span>
              </label>
              <textarea
                rows="7"
                className="form_control"
                placeholder="Write your message here..."
              ></textarea>
            </div>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button className="submit_btn" type="submit">
           
              <FormattedMessage id="SUBMIT"/>
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
