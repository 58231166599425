import React from "react";
import { Button } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SocialComponent from "../../Components/Social/SocialComponent";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { FormattedMessage } from 'react-intl';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Footer(props) {
  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer id="footer">
      <div className="footer_content">
        <div className="container">
          <div className="footer_content_top">
            <h2><FormattedMessage id="Subscribe" /></h2>
            <p><FormattedMessage id="Get-All" /></p>
            <div className="form_group">
              <input type="text" placeholder="Enter your Email..." />
              <Button><FormattedMessage id="Subscribes" /></Button>
            </div>
          </div>
          <div className="footer_content_bottom">
            <div className="content_title">
              <h4><FormattedMessage id="Apps" /></h4>
              <a href="#">
                <img
                  src={require("../../static/icons/android-download.png")}
                  alt=""
                />
              </a>
              <a href="#">
                <img
                  src={require("../../static/icons/app_store_badge.png")}
                  alt=""
                />
              </a>
            </div>

            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12} sm={4} md={4}>
                  <Item className="content_item">
                    <h4><FormattedMessage id="Address1" /></h4>
                    <address>
                    <FormattedMessage id="Footer" />
                    <FormattedMessage id="Footer1" />
                    <FormattedMessage id="Footer2" />
                    <FormattedMessage id="Tel" />
                    <FormattedMessage id="Footer3" />
                    <FormattedMessage id="E-mail  " />
                    </address>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Item className="content_item">
                    <h4><FormattedMessage id="Useful" /></h4>
                    <ul>
                      <li>
                        <Link to="about" onClick={handleScrollTop}>
                        <FormattedMessage id="About" />
                          
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="terms_and_conditions"
                          onClick={handleScrollTop}
                        >
                           <FormattedMessage id="Conditions" />
                          
                        </Link>
                      </li>
                      <li>
                        <Link to="cookies" onClick={handleScrollTop}>
                          
                          <FormattedMessage id="Cookies" />
                          
                        </Link>
                      </li>
                      <li>
                        <Link to="vacancy" onClick={handleScrollTop}>
                          
                          <FormattedMessage id="Vacancies" />
                        </Link>
                      </li>
                    </ul>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Item className="content_item">
                    <h4><FormattedMessage id="Useful-Links" /></h4>
                    <ul>
                      <li>
                        <Link
                          to="selling_and_buying_rule"
                          onClick={handleScrollTop}
                        >
                          <FormattedMessage id="Selling" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="local_advertising_rule"
                          onClick={handleScrollTop}
                        >
                         
                          <FormattedMessage id="Local" />
                        </Link>
                      </li>
                      <li>
                        <Link to="imprint" onClick={handleScrollTop}>
                          
                          <FormattedMessage id="Imprint" />
                        </Link>
                      </li>
                      <li>
                        <Link to="privacy_policy" onClick={handleScrollTop}>
                          
                          <FormattedMessage id="Privacy"/>
                        </Link>
                      </li>
                    </ul>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
      <div style={{backgroundColor:"var(--auto-text-danger)"}}>
        <div className="footer_end_content">
          <strong><FormattedMessage id="Autoby"/></strong>
          <SocialComponent></SocialComponent>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
